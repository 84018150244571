import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Card from '../Card';
import Button from '../Button';
import { Play, Trash2, Check } from 'lucide-react';
import '../../assets/bubblingLoader.css';
import Modal from '../Modal';

const SavedQueriesComponent = forwardRef(({ onQueryResult, onQueryStart, onSelectQuery, selectedQueries = [], isSelectionMode = false, displayMode = 'grid', currentUser, queries: providedQueries, onQueryItemClick }, ref) => {
  const [allQueries, setAllQueries] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryToDelete, setQueryToDelete] = useState(null);
  const [isDeletingQuery, setIsDeletingQuery] = useState(false);
  const [filter, setFilter] = useState('all');
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchSavedQueries = async () => {
    if (providedQueries) {
      setAllQueries(providedQueries);
      return;
    }
  
    setIsLoading(true);
    try {
      const response = await fetch('https://us-central1-polyram-1ddf1.cloudfunctions.net/getSavedQueries', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            userId: currentUser.email,
            isAdmin: currentUser.isAdmin  // Add this line
          }
        })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch saved queries');
      }
      const queriesData = await response.json();
  
      const sortedQueries = queriesData.sort((a, b) => (b.counter || 0) - (a.counter || 0));
  
      setAllQueries(sortedQueries);
      setError(null);
    } catch (err) {
      console.error('Error fetching saved queries:', err);
      setError('Failed to load saved queries. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedQueries();
  }, [currentUser.email, providedQueries]);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredQueries(allQueries.filter(q => !q.isPrivate || q.userId === currentUser.email));
    } else if (filter === 'my') {
      setFilteredQueries(allQueries.filter(q => q.userId === currentUser.email));
    } else if (filter === 'shared') {
      setFilteredQueries(allQueries.filter(q => !q.isPrivate && q.userId !== currentUser.email));
    }
  }, [filter, allQueries, currentUser.email]);

  useImperativeHandle(ref, () => ({
    refreshQueries: fetchSavedQueries
  }));

  const handleRunSavedQuery = async (query) => {
    onQueryStart();
    setErrorMessage(null);
    try {
      const response = await fetch('https://us-central1-polyram-1ddf1.cloudfunctions.net/runSavedQuery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: { savedQueryId: query.id, userId: currentUser.email }, context: { auth: true } })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Network response was not ok');
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      onQueryResult({
        ...data,
        userQuery: query.userQuery,
        name: query.name,
        isSavedQuery: true,
        config: {
          usedModel: data.usedModel,
          fallbackUsed: data.fallbackUsed,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error running saved query:', error);
      setErrorMessage(error.message);
      onQueryResult(null);
    }
  };

  const handleDeleteQuery = (query) => {
    setQueryToDelete(query);
    setIsModalOpen(true);
  };

  const confirmDeleteQuery = async () => {
    if (!queryToDelete) return;
    setIsDeletingQuery(true);
    try {
      const response = await fetch('https://us-central1-polyram-1ddf1.cloudfunctions.net/deleteSavedQuery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            savedQueryId: queryToDelete.id,
            userId: currentUser.email
          } 
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete query');
      }
      setFilteredQueries(prevQueries => prevQueries.filter(q => q.id !== queryToDelete.id));
      setIsModalOpen(false);
      setQueryToDelete(null);
    } catch (error) {
      console.error('Error deleting saved query:', error);
      setError('An error occurred while deleting the query. Please try again.');
    } finally {
      setIsDeletingQuery(false);
    }
  };

  const filterOptions = [
    { value: 'all', label: 'All Queries' },
    { value: 'my', label: 'My Queries' },
    { value: 'shared', label: 'Shared Only' },
  ];

  const renderFilterTags = () => (
    <div className="flex space-x-2 mb-4">
      {filterOptions.map((option) => (
        <button
          key={option.value}
          onClick={() => setFilter(option.value)}
          className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors duration-200 ${
            filter === option.value
              ? 'bg-secondary text-gray-700'
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );

  const renderQueryItem = (query) => {
    const isSelected = selectedQueries.includes(query.id);
    return (
      <div 
        key={query.id} 
        className={`p-4 ${displayMode === 'list' ? 'border-b' : ''} ${isSelected ? 'bg-primary bg-opacity-10' : ''} cursor-pointer`}
        onClick={() => {
          if (isSelectionMode) {
            if (onQueryItemClick) {
              onQueryItemClick(query);
            } else if (onSelectQuery) {
              onSelectQuery(query);
            }
          }
        }}
      >
        <div className="flex justify-between items-start">
          <h3 className="text-base font-semibold pr-2">{query.name}</h3>
          <span className={`px-2 py-1 text-xs font-semibold rounded-full ${query.isPrivate ? 'bg-secondary bg-opacity-20 text-secondary' : 'bg-gray-200 text-gray-600'}`}>
            {query.isPrivate ? 'Private' : 'Public'}
          </span>
        </div>
        <p className="text-sm text-gray-600 mt-1 mb-2">{query.userQuery}</p>
        <div className="flex justify-between items-center">
          <p className="text-xs text-gray-400">{query.userId}</p>
          {isSelectionMode ? (
            <div className={`w-6 h-6 border-2 rounded-full flex items-center justify-center ${isSelected ? 'bg-primary border-primary' : 'border-gray-300'}`}>
              {isSelected && <Check size={16} className="text-white" />}
            </div>
          ) : (
            <Button 
              onClick={(e) => {
                e.stopPropagation();
                handleRunSavedQuery(query);
              }}
              icon={Play}
              className="text-primary"
            >
              Run Query
            </Button>
          )}
          {query.userId === currentUser.email && !isSelectionMode && (
            <Button 
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteQuery(query);
              }}
              icon={Trash2}
              className="text-primary"
              aria-label="Delete query"
            />
          )}
        </div>
      </div>
    );
  };

  const renderQueryList = () => (
    <div className={`mt-8 ${displayMode === 'list' ? 'space-y-4' : ''}`}>
      <h2 className="text-gray-500 mb-6 text-xl font-semibold">Saved Queries</h2>
      {renderFilterTags()}
      {errorMessage && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p>{errorMessage}</p>
        </div>
      )}
      {filteredQueries.length === 0 ? (
        <p className="text-center text-gray-500">No queries found.</p>
      ) : (
        <div className={`${displayMode === 'list' ? 'space-y-4' : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'} ${isSelectionMode ? 'max-h-96 overflow-y-auto' : ''}`}>
          {filteredQueries.map(query => 
            displayMode === 'list' ? (
              renderQueryItem(query)
            ) : (
              <Card key={query.id} className={`${isSelectionMode ? 'h-auto' : 'h-48'} relative`}>
                {renderQueryItem(query)}
              </Card>
            )
          )}
        </div>
      )}
    </div>
  );

  if (isLoading) return <div className="text-center py-4">Loading saved queries...</div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div>
      {renderQueryList()}
      {!isSelectionMode && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setQueryToDelete(null);
          }}
          onSubmit={confirmDeleteQuery}
          title="Confirm Dejustify-betweeletion"
          message={`Are you sure you want to delete the query "${queryToDelete?.name}"?`}
          isInput={false}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isDeletingQuery}
        />
      )}
    </div>
  );
});

export default SavedQueriesComponent;